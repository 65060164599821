import * as CookieConsent from 'vanilla-cookieconsent';
import { de } from './consent/de';
import { en } from './consent/en';
import '@orestbida/iframemanager';

const language = document.documentElement.lang.substring(0, 2);
const im = iframemanager();

window.CookieConsent = CookieConsent;
window.im = im;

CookieConsent.run({
    mode: 'opt-in',
    autoShow: false,
    revision: 0,
    manageScriptTags: true,
    disablePageInteraction: false,

    cookie: {
        name: 'privacy_preferences',
        domain: window.location.hostname,
        path: '/',
        expiresAfterDays: 365,
        sameSite: 'Lax',
    },

    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom right',
            flipButtons: false,
            equalWeightButtons: true,
        },
        preferencesModal: {
            layout: 'box',
            position: 'left',
            flipButtons: false,
            equalWeightButtons: true,
        },
    },

    categories: {
        necessary: {
            enabled: true,
            readOnly: true,
        },
        analytics: {
            enabled: true,
            readOnly: true,
            autoClear: {
                cookies: [],
                reloadPage: true,
            },
            services: {
                matomo: {
                    label: 'Matomo',
                },
            },
        },
        media: {
            autoClear: {
                cookies: [],
                reloadPage: true,
            },
            services: {
                youtube: {
                    label: 'YouTube',
                    onAccept: () => im.acceptService('youtube'),
                    onReject: () => im.rejectService('youtube'),
                },
                vimeo: {
                    label: 'Vimeo',
                    onAccept: () => im.acceptService('vimeo'),
                    onReject: () => im.rejectService('vimeo'),
                },
            },
        },
    },

    language: {
        default: 'de',
        autoDetect: 'document',
        translations: {
            de,
            en,
        },
    },
});

const iframemanagerCookie = {
    path: '/',
    sameSite: 'Lax',
    domain: window.location.hostname,
    expiration: 365,
};

const triggerConsentManagerEvent = (service, accepted) => {
    const consentmanagerEvent = new CustomEvent('consentmanager', {
        detail: { service, accepted },
    });
    window.dispatchEvent(consentmanagerEvent);
};

im.run({
    currLang: language,

    onChange({ changedServices, eventSource }) {
        if (eventSource.type === 'click') {
            const category = 'media';
            const acceptedServices = CookieConsent.getUserPreferences().acceptedServices[category];
            CookieConsent.acceptService([...acceptedServices, ...changedServices], category);
        }

        if (eventSource.action === 'accept') {
            triggerConsentManagerEvent(eventSource.service, eventSource.action == 'accept');
        }
    },

    services: {
        youtube: {
            embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
            iframe: {
                allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
            },
            cookie: {
                ...iframemanagerCookie,
                name: 'im_youtube',
            },
            languages: {
                de: {
                    notice: 'Dieser Inhalt wird von YouTube angeboten. Wenn Sie den Inhalt anzeigen, werden personenbezogene Daten an den Drittanbieter übertragen. Mehr Informationen finden sie in den <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener">Nutzungsbedingungen</a> von YouTube.',
                    loadBtn: 'Video anzeigen',
                    loadAllBtn: 'Inhalte von YouTube immer anzeigen',
                },
                en: {
                    notice: 'This content is provided by YouTube. By viewing this content, personal data may be transmitted to third parties. Read more in the <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener">terms of service</a> of YouTube.',
                    loadBtn: 'Load video',
                    loadAllBtn: 'Always show content by YouTube',
                },
            },
        },
        vimeo: {
            embedUrl: 'https://player.vimeo.com/video/{data-id}?title=0&byline=0&portrait=0&dnt=1',
            iframe: {
                allow: 'fullscreen; picture-in-picture, allowfullscreen',
            },
            cookie: {
                ...iframemanagerCookie,
                name: 'im_vimeo',
            },
            languages: {
                de: {
                    notice: 'Dieser Inhalt wird von Vimeo angeboten. Wenn Sie den Inhalt anzeigen, werden personenbezogene Daten an den Drittanbieter übertragen. Mehr Informationen finden sie in den <a href="https://vimeo.com/terms" target="_blank" rel="noopener">Nutzungsbedingungen</a> von Vimeo.',
                    loadBtn: 'Video anzeigen',
                    loadAllBtn: 'Inhalte von Vimeo immer anzeigen',
                },
                en: {
                    notice: 'This content is provided by Vimeo. By viewing this content, personal data may be transmitted to third parties. Read more in the <a href="https://vimeo.com/terms" target="_blank" rel="noopener">terms of service</a> of Vimeo.',
                    loadBtn: 'Load video',
                    loadAllBtn: 'Always show content by Vimeo',
                },
            },
        },
    },
});
